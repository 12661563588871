<template>
    <div class="pages p20">
        <div class="title-btn-block">
            <div class="title-block">Qo'shimcha ishlar</div>
            <div style="display:flex; gap:10px">
                <!-- <router-link :to="{ name: 'Home' }">
                    <div class="my-btn cr1 el-button">
                        <i class="el-icon-d-arrow-left"></i> Создать
                    </div>
                </router-link> -->
                <button @click="focusFunction" type="success" style="height:40px; width: 100px; background-color: #00CC00; border: none;border-radius: 5px;">
                  <img src="./../../../public/img/barcode-scanner.png" alt="" style="max-height:100%; filter:brightness(0) invert(1)">
                </button>
                <router-link :to="{ name: 'additionalWorkAdd', params:params} ">
                    <div class="my-btn cr1 el-button">
                        <i class="el-icon-d-arrow-left"></i> Orqaga
                    </div>
                </router-link>
            </div>
        </div>

        <div class="content-block">
            <div class="datetime-end bg p20 mb20">
              
               <el-row :gutter="20">
               
                  
                  <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                    <div class="seloction mb20 " style="text-align:center">
                      <img src="./../../../public/img/scanner.png" alt="" style="width:100px;">
                      <h4 class="title-block">Iltimos ID raqamingizni o'qiting</h4>
                    </div>
                  </el-col>

                  <el-col :xl="24">
                    <div class="seloction mb20 " style="text-align:center" v-if="scannerStaff">
                      <img  :src="
                          scannerStaff.image
                            ? baseUrl + scannerStaff.image.path
                            : 'img/avatar.jpeg'
                            "
                         alt="" style="width:200px;">
                      <h4 class="title-block">{{scannerStaff.name +' ' + scannerStaff.last_name }}</h4>
                    </div>
                  </el-col>
               </el-row>
               <input
                  class="op-0"
                  type="text"
                  id="fname"
                  name="fname"
                  ref="inputId"
                  v-model="staffId"
                  style="opacity: 0; width: 10px; height: 0px;"

                />
            </div>
        </div>
    </div>
</template>
<script>
import {setItem, getItem } from "@/utils/storage";
import { RouterLink } from 'vue-router'
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  props: [ "id"],
  data() {
    return {
       baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      salarys:null,
      complaints:[],
      staffId: null,
      scannerStaff:null,
      params:{},
      advance_salary:null,
      additionalWorkCache:{
        position_id:"",
        staff_id:"",
        created_id:getItem("userId"),
        additional_work_reason_id:this.reason_id,
        performance_type:this.performance_type
      }
     
    };
  },
  computed:{
    ...mapGetters({
      reasons: "reason/list",
    })
  },
  mounted() {
    this.reasonsAction();
    this.$refs.inputId.focus();
  },
  created(){
    this.additionalWorkCache.performance_type = getItem('performance_type');
    this.additionalWorkCache.additional_work_reason_id = getItem('additional_work_reason_id');
    this.params = this.$route.params;
  },
  watch:{
    staffId() {
      if (this.staffId.toString().length == 5) {
        const loading = this.$loading({
          customClass: "login-loading",
          lock: true,
          text: "Загрузка",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        var is_loading = true;
        if (this.staffId != null && this.staffId.toString().length == 5) {
          this.upadetAssent(this.staffId)
            .then((res) => {
              loading.close();
              is_loading = false;
              if (res.status == 200) {
                this.scannerStaff = res.data.result.data.staff;
                this.additionalWorkCache.position_id = this.scannerStaff.position_id;
                this.additionalWorkCache.staff_id = this.scannerStaff.id;
                this.storeAdditionalCache();
              } else {
                this.$alert(res);
              }
            })
            .catch((err) => {
              this.$alert(err);
              loading.close();
              is_loading = false;
            });
        } else {
          this.staffId = null;
        }

        loading.close();
        is_loading = false;
      }
      if (this.staffId.toString().length > 5) {
        this.staffId = null;
      }
    },
  },
  methods: {
    ...mapActions({
      updateList: "myRequests/index",
      reasonsAction: "reason/index",
      upadetAssent: "assent/staffInfo",
    }),
    storeAdditionalCache() {
           axios.post(process.env.VUE_APP_URL + '/additional_work/cache/store', {
                  ...this.additionalWorkCache
                })
                .then((res) => {
                    this.focusFunction();
                     var self = this;
                        this.$alert(res);
                      setTimeout(function() {
                        self.scannerStaff = null;
                      }, 2000);
                })
                .catch((error) => {
                     this.$alert(error);
                });
    },
    getTable() {
      this.updateList(getItem("userId"))
        .then((res) => {
          this.assents = res.take_assent
          this.advance_salary = res.advance_salary
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    focusFunction(){
      this.staffId = '';
      this.$refs.inputId.focus();
    },
     getParam(){
       console.log('reason_id', this.reason_id);
       console.log('performance_type', this.performance_type);
      
    }
  },
};
</script>
<style lang="scss">
  .op-0{
    opacity: 0;
  }
</style>
